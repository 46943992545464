import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Heading,
  HStack,
  Text,
  useToken,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'

import ExpansionSymbol from '../../components/ExpansionSymbol'
import type { ProductWithCardVersions } from '../../Types'

function Section({
  title,
  startOpen,
  products,
  activeProductCodes,
  setActiveProductCodes,
}: {
  title: string
  startOpen?: boolean
  products: ProductWithCardVersions[]
  activeProductCodes: string[]
  setActiveProductCodes: (productCodes: string[]) => void
}) {
  const [open, setOpen] = useState(startOpen ?? true)
  const [brand400] = useToken('colors', ['brand.500'])

  const productOnClick = (
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    product: ProductWithCardVersions
  ) =>
    !(event.ctrlKey || event.metaKey)
      ? setActiveProductCodes([product.code])
      : activeProductCodes.includes(product.code)
      ? setActiveProductCodes([
          ...activeProductCodes.filter((p) => p != product.code),
        ])
      : setActiveProductCodes([...activeProductCodes, product.code])

  return (
    <>
      <HStack
        mt="4"
        onClick={() => setOpen((open) => !open)}
        width="100%"
        justifyContent="space-between"
        alignItems="baseline"
        mb={2}
        pb={1}
        borderBottom={`2px solid ${brand400}`}
      >
        <Heading as="h3" size="md">
          {title}
        </Heading>
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </HStack>
      <Box display={open ? 'block' : 'none'}>
        {products
          .slice()
          .sort((a, b) => (a.code > b.code ? 1 : -1))
          .map((product) => (
            <HStack
              key={product.code}
              onClick={(event) => productOnClick(event, product)}
              borderRadius="sm"
              my={0.5}
              py={1}
              pl={1.5}
              alignItems="center"
              bg={
                activeProductCodes.find((p) => p === product.code)
                  ? 'brand.400'
                  : 'transparent'
              }
              color={
                activeProductCodes.find((p) => p === product.code)
                  ? 'white'
                  : 'brand.50'
              }
              fontWeight={
                activeProductCodes.find((p) => p === product.code)
                  ? '600'
                  : 'normal'
              }
            >
              <Badge colorScheme="gray">{product.code}</Badge>
              {product.expansionSymbol && (
                <ExpansionSymbol
                  expansionSymbol={product.expansionSymbol}
                  height="20px"
                  width="20px"
                  src={`/icons/expansion symbols/${product.expansionSymbol}`}
                />
              )}
              <Text lineHeight={1} pb={0.5} my={-1}>
                {product.name}
              </Text>
            </HStack>
          ))}
      </Box>
    </>
  )
}

function SideBar({
  products,
  activeProductCodes,
  setActiveProductCodes,
}: {
  products: ProductWithCardVersions[]
  activeProductCodes: string[]
  setActiveProductCodes: (productCodes: string[]) => void
}) {
  const repackedProducts = useMemo(
    () => products.filter((p) => p.category === 'OFFICIAL' && p.isRepackage),
    [products]
  )
  const originalProducts = useMemo(
    () => products.filter((p) => p.category === 'OFFICIAL' && !p.isRepackage),
    [products]
  )
  const communityProducts = useMemo(
    () => products.filter((p) => p.category === 'COMMUNITY'),
    [products]
  )

  const [showRepackaged, setShowRepackaged] = useState(true)
  const [showOriginal, setShowOriginal] = useState(true)
  //const [showCommunity, setShowCommunity] = useState(false)

  const [brand400] = useToken('colors', ['brand.400'])
  return (
    <>
      {/* <Alert
        status="error"
        borderRadius={8}
        width="auto"
        py={2}
        color="red.800"
        mt="6"
        fontSize={12}
        overflow="initial"
      >
        <AlertIcon />
        <Text>
          Card listings might not be fully accurate, proceed with caution.
        </Text>
      </Alert> */}

      <HStack
        mt="4"
        onClick={() => setShowRepackaged((showRepackaged) => !showRepackaged)}
        width="100%"
        justifyContent="space-between"
        alignItems="baseline"
        borderBottom={`2px solid ${brand400}`}
        pb={1}
      >
        <Heading size="lg" mt="6">
          Repackaged Products
        </Heading>
        {showRepackaged ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </HStack>

      <Box display={showRepackaged ? 'block' : 'none'}>
        <Section
          title="Core set"
          products={repackedProducts.filter((p) => p.type === 'CORE')}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Starter Decks"
          products={repackedProducts.filter((p) => p.type === 'STARTER_DECK')}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Scenario Packs"
          products={repackedProducts.filter((p) => p.type === 'SCENARIO_PACK')}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Angmar Awakened Cycle"
          products={repackedProducts.filter(
            (p) =>
              p.cycle === 'Angmar Awakened' &&
              ['CAMPAIGN_EXPANSION', 'HERO_EXPANSION'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Dream-chaser Cycle"
          products={repackedProducts.filter(
            (p) =>
              p.cycle === 'Dream-chaser' &&
              ['CAMPAIGN_EXPANSION', 'HERO_EXPANSION'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Ered Mithrin Cycle"
          products={repackedProducts.filter(
            (p) =>
              p.cycle === 'Ered Mithrin' &&
              ['CAMPAIGN_EXPANSION', 'HERO_EXPANSION'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="The Lord of the Rings Sagas"
          products={repackedProducts.filter(
            (p) =>
              p.cycle === 'The Lord of the Rings' && p.type === 'SAGA_EXPANSION'
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
      </Box>

      <HStack
        mt="4"
        onClick={() => setShowOriginal((showOriginal) => !showOriginal)}
        width="100%"
        justifyContent="space-between"
        alignItems="baseline"
        borderBottom={`2px solid ${brand400}`}
        pb={1}
      >
        <Heading size="lg" mt="6">
          Original Products
        </Heading>
        {showOriginal ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </HStack>

      <Box display={showOriginal ? 'block' : 'none'}>
        <Section
          title="Core sets"
          products={originalProducts.filter((p) => p.type === 'CORE')}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Shadows of Mirkwood Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Shadows of Mirkwood' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Dwarrowdelf Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Dwarrowdelf' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Against the Shadow Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Against the Shadow' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="The Ring-maker Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'The Ring-maker' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Angmar Awakened Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Angmar Awakened' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Dream-chaser Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Dream-chaser' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Haradrim Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Haradrim' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Ered Mithrin Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Ered Mithrin' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Vengeance of Mordor Cycle"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'Vengeance of Mordor' &&
              ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="The Hobbit Sagas"
          products={originalProducts.filter(
            (p) => p.cycle === 'The Hobbit' && p.type === 'SAGA_EXPANSION'
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="The Lord of the Rings Sagas"
          products={originalProducts.filter(
            (p) =>
              p.cycle === 'The Lord of the Rings' && p.type === 'SAGA_EXPANSION'
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Nightmare Decks"
          startOpen={false}
          products={originalProducts.filter(
            (p) => p.type === 'NIGHTMARE_EXPANSION'
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
        <Section
          title="Standalone Scenarios"
          startOpen={false}
          products={originalProducts.filter(
            (p) => p.type === 'STANDALONE_SCENARIO'
          )}
          activeProductCodes={activeProductCodes}
          setActiveProductCodes={setActiveProductCodes}
        />
      </Box>
    </>
  )
}

export default SideBar
